<template>
<mu-bottom-sheet :open="isOpen" @close="close" sheet-class="phi-person-info">
    <div class="cover phi-media" v-if="person">
        <div class="avatar phi-media-figure">
            <img :src="person.avatar" :alt="person.firstName" />
        </div>
        <div class="name phi-media-body">
            <span class="first">{{ person.firstName }}</span>
            <span class="last">{{ person.lastName }}</span>
        </div>
    </div>
    <div class="contents">
        <div class="roles">
            <div v-for="role in roles" class="role">
                <template v-if="role.role">
                    <label>{{ $t(role.role.singular) }}</label>
                    <ul>
                        <li v-for="node in role.nodes">{{ node.name }}</li>
                    </ul>
                </template>

                <template v-if="role.relation">
                    <label>{{ $t(role.relation) }}</label>
                    <ul>
                        <li v-for="relative in role.relatives">
                            <span>{{ relative.person.firstname }} {{ relative.person.lastname }}</span>
                            <span v-if="relative.node">({{ relative.node.name }})</span>
                        </li>
                    </ul>
                </template>
            </div>
        </div>
    </div>
</mu-bottom-sheet>
</template>

<script>
export default {
    props: {
        api: {
            required: true
        },

        person: {
            required: true
        }
    },

    data() {
        return {
            roles: null,
            isOpen: false
        }
    },

	methods: {
		fetch() {
            if (!this.person) {
                return this.close();
            }
			this.fetchRoles();
            this.isOpen = true;
		},

		fetchRoles() {
            this.api
                .get(`/v3/people/${this.person.id}/roles`)
                .then(roles => this.roles = roles);
		},

        close() {
            this.roles  = null;
            this.isOpen = false;
            this.$emit("close");
        }
	},

    mounted() {
        this.fetch();
    },

	watch: {
		person() {
            this.fetch();
        }
	}
}
</script>

<style lang="scss">
.phi-person-info {
    top: 30%;
    overflow-x: hidden;
    overflow-y: auto;

    .cover {
        background-color: #1C89B8;
        padding: 0 16px;
        padding-bottom: 8px;

        align-items: center;

        .avatar,
        .name {
            position: relative;
            top: 16px;
        }

        .avatar {
            width: 86px;
            height: 86px;
            overflow: hidden;

            border-radius: 12px;
            border: 2px solid #fff;
            background-color: #fff;

            img {
                width: 100%;
            }
        }

        .name {
            font-size: 1.4em;
            color: #fff;
            text-shadow: 0 1px 6px #000, 0 1px 3px #000;
        }
    }

    .contents {
        padding: 48px 24px 12px;
    }

    .role {
        margin-bottom: 36px;

        label {
            display: block;
            font-size: 14px;
            color: #666;
            margin-bottom: 6px;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: block;
                margin-bottom: 6px;
            }
        }
    }
}

</style>