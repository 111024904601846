<template>
<phi-slider-panel side="right" :open="isOpen" @closed="close" background-color="#fff">
    <div class="phi-post-info" v-if="post">

        <div class="author">

            <div class="person phi-media">
                <div class="phi-avatar phi-media-figure">
                    <phi-person-avatar :person="post.author"></phi-person-avatar>
                </div>
                <div class="phi-media-body">
                    <div class="name">
                        <span class="first">{{ post.author.firstName }} </span>
                        <span class="last">{{ post.author.lastName }} {{ post.author.lastName2 }}</span>
                    </div>
                    <div class="date">{{ $date(post.publishDate) }}</div>
                </div>
            </div>

            <div class="roles" v-if="roles.length">
                <div v-for="role in roles" class="role">
                    <template v-if="role.role">
                        <label>{{ $t(role.role.singular) }}</label>
                        <ul>
                            <li v-for="node in role.nodes">{{ node.name }}</li>
                        </ul>
                    </template>

                    <template v-if="role.relation">
                        <label>{{ $t(role.relation) }}</label>
                        <ul>
                            <li v-for="relative in role.relatives">
                                <span>{{ relative.person.firstname }} {{ relative.person.lastname }}</span>
                                <span v-if="relative.node">({{ relative.node.name }})</span>
                            </li>
                        </ul>
                    </template>
                </div>
            </div>
        </div>

        <div class="contents">
            <div class="trail" v-if="showTrail">
                <button type="button" class="trail-handle" @click="trailIsShown = !trailIsShown">···</button>
                <phi-drawer :open="trailIsShown" linear>
                    <phi-post-trail :post="trail"></phi-post-trail>
                </phi-drawer>
            </div>

            <div class="quotes" v-if="showQuotes">
                <div class="post quote phi-media" v-for="quote in quotes">
                    <div class="phi-media-figure phi-avatar">
                        <phi-person-avatar :person="quote.author"></phi-person-avatar>
                    </div>
                    <div class="phi-media-body">
                        <div class="post-author">{{quote.author.firstName}} {{quote.author.lastName}} {{quote.author.lastName2}}</div>
                        <div class="post-date">{{ $date(quote.publishDate, "time") }}</div>
                        <div class="post-description">{{quote.description}}</div>
                    </div>
                </div>
            </div>

            <div class="slot">
                <slot></slot>
            </div>
        </div>

        <div class="stubs" v-if="showAudience">
            <label>{{ $t('noun.sentTo.singular') }}</label>
            <div v-for="stub in stubs" :key="stub.id" class="person phi-media">
                <div class="phi-avatar phi-media-figure">
                    <phi-person-avatar :person="stub.person"></phi-person-avatar>
                </div>
                <div class="phi-media-body">
                    <div class="name">
                        <span class="first">{{ stub.person.firstName }} </span>
                        <span class="last">{{ stub.person.lastName }} {{ stub.person.lastName2 }}</span>
                    </div>

                    <div class="read-date" :class="{unread: !stub.readDate, read: !!stub.readDate}" v-if="showReadDate">
                        <template v-if="!!stub.readDate" >
                            <mu-icon value="done_all"></mu-icon>
                            <span>{{ $date(stub.readDate) }}</span>
                        </template>

                        <template v-if="!stub.readDate" >
                            <mu-icon value="done"></mu-icon>
                            <span>{{ $t('adj.unread.singular') }}</span>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</phi-slider-panel>
</template>

<script>
import Vue from 'vue';
import PhiSliderPanel from '../Slider/Panel.vue';
import PhiPostTrail from './Trail.vue';
import PhiPersonAvatar from '@/components/Phi/Person/Avatar.vue';

export default {
    name: 'phi-post-info',
    components: {
        PhiSliderPanel,
        PhiPostTrail,
        PhiPersonAvatar
    },

    props: {
        post: {
            required: true
        },

        showTrail: {
            type: Boolean,
            default: true
        },

        showAudience: {
            type: Boolean,
            default: true
        },

        showReadDate: {
            type: Boolean,
            default: false
        },

        showRoles: {
            type: Boolean,
            default: false
        }

        /*,

        showQuotes: {
            type: Boolean,
            default: false
        }*/
    },

    data() {
        return {
            stubs: null,
            trail: null,
            quotes: [],
            roles: [],

            isOpen: false,
            trailIsShown: false,

            showQuotes: false // DISABLE QUOTES ALWAYS
        }
    },

    computed: {
        api() {
            return this.$store.state.api;
        }
    },

	methods: {
		fetch() {
            if (!this.post) {
                return this.close();
            }
			this.showTrail && this.fetchTrail();
			this.showAudience && this.fetchStubs();
			this.showQuotes && this.fetchQuotes();
			this.showRoles && this.fetchRoles();
            this.isOpen = true;
		},

		fetchStubs() {
            return this.api
                .get(`/posts/${this.post.id}/stubs?all=1`)
                .then(stubs => this.stubs = stubs);
		},

        fetchTrail() {
            return this.api.get(`/posts/${this.post.id}/trail`, {limit: 5})
                .then(trail => this.trail = trail);
        },

        fetchQuotes() {
            return this.api.get(`/posts/${this.post.id}/quotes`)
                .then(quotes => this.quotes = quotes);
        },

        fetchRoles() {
            return this.api
                .get(`/v3/people/${this.post.author.id}/roles`)
                .then(roles => this.roles = roles);
        },

        close() {
            this.stubs        = null;
            this.trail        = null;
            this.isOpen       = false;
            this.trailIsShown = false;

            this.$emit("close");
        }
	},

    mounted() {
        this.fetch();
    },

	watch: {
		post() {
            this.fetch();
        }
	}
}
</script>

<style lang="scss">
.phi-post-info {
    padding: 18px;

    .author {
        .person {
            padding: 0;
            font-size: 0.9em;

            .name {
                color: #222;
                font-weight: bold;
            }

            .date {
                color: #666;
            }
        }

        .roles {
            margin: 12px 0;

            .role {
                margin-bottom: 36px;

                label {
                    display: block;
                    font-size: 14px;
                    color: #666;
                    margin-bottom: 6px;
                }

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    li {
                        display: block;
                        margin-bottom: 6px;
                    }
                }
            }
        }

    }

    .contents {
        margin: 24px 0;

        .description {
            font-size: 1em;
            white-space: pre-wrap;
        }
    }

    .slot {
        margin: 32px 0;
    }

    .stubs {
        label {
            display: block;
            font-size: 13px;
            margin-bottom: 16px;
            color: #777;
        }

        .person {
            margin-bottom: 22px;
            padding: 0;
        }

        .phi-avatar {
            width: 28px !important;
            max-width: 28px !important;
            min-width: 28px !important;

            height: 28px !important;
            max-height: 28px !important;
            min-height: 28px !important;
        }

        .read-date {
            color: #777;
            font-size: 12px;
            display: flex;
            align-items: center;

            .mu-icon {
                font-size: 18px;
                margin-right: .3em;
                color: #bbb;
            }

            &.read .mu-icon {
                color: #8bc34a;
            }
        }

    }

    .trail-handle {
        background: transparent;
        font-size: 20px;
        border: none;
        color: #999;
        cursor: pointer;
    }

    .quotes {
        border-left: 2px solid dodgerblue;
        margin: 12px 0;

        .post {

            font-size: 13px;
            padding: 6px;

            .post-author {
                color: #000;
                font-weight: bold;
            }

            .phi-media-figure {
                margin-right: 12px;
            }

            .post-date {
                font-size: 11px;
                color: #777;
            }

            .phi-avatar {
                width: 28px !important;
                max-width: 28px !important;
                min-width: 28px !important;

                height: 28px !important;
                max-height: 28px !important;
                min-height: 28px !important;
            }
        }
    }


}
</style>