<template>
	<div class="phi-slider-panel">
		<div class="modal-mask" @click="closePanel()"></div>
		<div class="slider-panel"
			v-bind:style="styleConfiguration"
			v-bind:class="{ open: open }"
		>
			<slot></slot>
		</div>
	</div>
</template>

<script>
import Hammer from 'hammerjs';

export default {
    name: "phi-slider-panel",

	data() {
        return {
			panel: null,
			modalMask: null,
			portraitW: "85vw",
			landscapeW: "40vw",
			hammer: null
		}
    },

	props: {
		open: {
			type: Boolean,
			required: false,
			default: false
		},

		side: {
			type: String,
			required: false,
			default: "left",
			validator: value => ["left", "right"].indexOf(value) >= 0
		},

		backgroundColor: {
			type: String,
			required: false,
			default: "#F3F3F3"
		}
	},

	computed:{
		styleConfiguration: function() {
			return {
				width: this.width,
				left: this.side === "left" ? "-"+this.width : "100vw",
				backgroundColor: this.backgroundColor
			}
		},

		width: function() {
			let w = this.portraitW;
			if (window.screen.width > window.screen.height) {
				w = this.landscapeW;
			}

			return w;
		}
	},

	watch: {
		open: function(isOpen) {
			isOpen ? this.openPanel() : this.closePanel();
		}
	},

	methods: {
		openPanel() {
			this.panel.classList.add("open");
			this.modalMask.classList.add("open");

			if (this.side === "left") {
				this.panel.style.setProperty('--width-to-hide', this.width);
			} else {
				this.panel.style.setProperty('--width-to-hide', "-" + this.width);
			}

			this.panel.scrollTop = 0;

			this.$emit("opened", true);
            document.body.style.overflow = "hidden";
		},

		closePanel() {
			this.panel.classList.remove("open");
			this.modalMask.classList.remove("open");

			if (this.side === "left") {
				this.panel.style.setProperty('--width-to-hide', this.width);
			} else {
				this.panel.style.setProperty('--width-to-hide', "-" + this.width);
			}

			this.$emit("closed", true);
            document.body.style.overflow = "auto";
		},

		addGlobalEvents() {
			var panel = this.$el.querySelector(".slider-panel");
			panel.style.setProperty('--real-viewport-height', window.innerHeight + "px");

			//lets try to deal with screen orientation :s
			window.addEventListener("resize", (e) => {
				panel = this.$el.querySelector(".slider-panel");
				let newW = null;

				if (window.screen.width > window.screen.height) {
					newW = this.landscapeW;
				} else {
					newW = this.portraitW;
				}

				panel.style.setProperty("width", newW);

				if (this.side === "left") {
					panel.style.setProperty('--width-to-hide', newW);
				} else {
					panel.style.setProperty('--width-to-hide', "-" + newW);
				}

				panel.style.setProperty('--real-viewport-height', window.innerHeight + "px");
			});
		}
	},

    mounted() {
		this.modalMask = this.$el.querySelector(".modal-mask");
		this.panel     = this.$el.querySelector(".slider-panel");

        if ("ontouchstart" in window || !!window.DocumentTouch && document instanceof window.DocumentTouch) {
            this.hammer    = new Hammer(this.panel);
            this.hammer.on("panend", this.closePanel);
        }

		this.addGlobalEvents();

		document.body.appendChild(this.$el);

        this.$router.beforeEach((to, from, next) => {
            this.closePanel();
            next();
        });
    }
}

</script>

<style scoped>
.modal-mask {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0,0,0,0.6);
	visibility:hidden;
	display: none;
}

.modal-mask.open {
	visibility: visible;
	display: block;
}

.slider-panel {
	--real-viewport-height: 100vh;
	height: var(--real-viewport-height);
    position: fixed;
    z-index: 9;
    top: 0;
    overflow-x: hidden;
    transition: 220ms;
}

.slider-panel.open {
	--width-to-hide: 0vw;
	transform: translateX(var(--width-to-hide));
}
</style>